<template>
  <div class="flex justify-between md:mx-auto items-center py-4 md:w-2/3">
    <!-- Logo -->
    <div>
      <transition name="slide-logo" mode="in-out">
        <img
          v-show="logo_transition"
          class="object-scale-down h-20"
          src="../assets/img/LoginLogo.png"
        />
      </transition>
    </div>

    <!-- Navigation -->
    <div class="flex items-center space-x-8">
      <div
        class="text-white flex flex-col items-center cursor-pointer "
        @click="handleLogout"
      >
        Logout
        <span
          class="w-1 h-1 rounded-full mt-1"
          style="background-color: #00A388"
        ></span>
      </div>
      <div
        class="text-white flex flex-col items-center cursor-pointer "
        @click="$router.push('/admin')"
      >
        Dashboard
        <span
          class="w-1 h-1 rounded-full mt-1"
          style="background-color: #00A388"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminHeader",
  data() {
    return {
      logo_transition: false
    };
  },
  mounted() {
    this.logo_transition = true;
  },
  methods: {
    handleLogout() {
      // Clear auth data from store
      this.$store.dispatch("auth/logout");

      // Clear localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("access");

      // Redirect to admin login
      this.$router.push({ name: "AdminSignIn" });
    }
  }
};
</script>

<style scoped></style>
