<template>
  <div class="min-h-screen bg-teal-900 px-6">
    <AdminHeader />
    <!-- Main Content -->
    <div class="flex flex-col">
      <div class="flex flex-col items-center">
        <div class="flex flex-col w-full max-w-md mt-4 px-4 space-y-4">
          <!-- Menu Buttons -->
          <div class="text-white text-xl text-left mt-8">
            Dashboard<span class="ml-1 text-4xl leading-none" style="color: #00A388"
            >.</span
          >
          </div>
          <button
            @click="$router.push('/admin/stats')"
            class="w-full py-4 bg-emerald-400 text-white rounded hover:bg-emerald-500 transition-colors"
          >
            Stats
          </button>

          <button
            @click="$router.push('/admin/vendors')"
            class="w-full py-4 bg-emerald-400 text-white rounded hover:bg-emerald-500 transition-colors"
          >
            Vendors
          </button>

          <button
            @click="$router.push('/admin/customers')"
            class="w-full py-4 bg-emerald-400 text-white rounded hover:bg-emerald-500 transition-colors"
          >
            Customers
          </button>

          <button
            @click="$router.push('/admin/message')"
            class="w-full py-4 bg-emerald-400 text-white rounded hover:bg-emerald-500 transition-colors"
          >
            Message
          </button>

          <button
            @click="$router.push('/admin/logs')"
            class="w-full py-4 bg-emerald-400 text-white rounded hover:bg-emerald-500 transition-colors"
          >
            Logs
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeader from "@/components/AdminHeader.vue";

export default {
  name: "DashboardHome",
  components: {
    AdminHeader
  }
};
</script>

<style>
.bg-teal-900 {
  background-color: #06262d;
}

.bg-emerald-400 {
  background-color: #00a388;
}

.bg-emerald-500:hover {
  background-color: #009b7d;
}

.header-padding {
  padding-left: 15%;
  padding-right: 15%;
}
</style>
